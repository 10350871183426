import React, { useContext } from 'react';
import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import {
  WorkOrderTypeOfServiceEnum,
} from 'stores/Classes/WorkOrderObject';
import WorkOrderDetailsContext from 'containers/WorkOrder/WorkOrderDetailsContext';

const { Text } = Typography;

const WorkOrderSummary = () => {
  const {
    state: { currentWorkOrder },
    shopUsers,
  } = useContext(WorkOrderDetailsContext);

  let createdByName = '';
  let assignedToName = '';

  if (shopUsers.length) {
    const createdBy = shopUsers.find(
      (user) => user.id === currentWorkOrder?.created_by
    );
    createdByName =
      createdBy?.getFullName().trim() || currentWorkOrder?.created_by;
    const assignedTo = shopUsers.find(
      (user) => user.id === currentWorkOrder?.assigned_to
    );
    assignedToName =
      assignedTo?.getFullName().trim() || currentWorkOrder?.assigned_to;
  }

  return (
    <Row gutter={[8, 16]}>
      <Col xs={12} sm={6}>
        <div>WO Number:</div>
        <Text strong>{currentWorkOrder?.invoice_number}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Asset Type:</div>
        <Text strong>{currentWorkOrder?.asset_type}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Select Asset:</div>
        <Text strong>{currentWorkOrder?.car_name}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Status:</div>
        <Text strong>{currentWorkOrder?.status}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Repair Type:</div>
        <Text strong>{WorkOrderTypeOfServiceEnum[currentWorkOrder?.type]}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Created By:</div>
        <Text strong>{createdByName}</Text>
      </Col>
      <Col xs={12} sm={6}>
        <div>Assigned To:</div>
        <Text strong>{assignedToName}</Text>
      </Col>
    </Row>
  );
};

WorkOrderSummary.propTypes = {
  workOrder: PropTypes.object,
  shopUsers: PropTypes.array,
};

export default WorkOrderSummary;
