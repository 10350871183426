import React from 'react';
import { Card, Col, Row, Spin, Typography } from 'antd';
import './OverdueDueSoonCounterStyles.less';

const { Text } = Typography;

interface SafetyInspectionProps {
  isLoading?: boolean;
  counts: {
    safety_overdue_count: any;
    safety_due_soon_count: any;
  };
  handleClick: (label: string) => void;
}

const SafetyInspectionsCountWidget: React.FC<SafetyInspectionProps> = ({
  isLoading,
  counts,
  handleClick,
}) => {
  return (
    <Card>
      <div className="card-title">
        <Text strong>DOT Inspections</Text>
      </div>
      <Row style={{ marginTop: 15 }} gutter={[16, 16]}>
        <Col span={24}>
          <div className="safety-inspection-col">
            {isLoading ? (
              <Spin tip="Loading..." />
            ) : (
              <>
                <div
                  className="clickable-section"
                  onClick={() => handleClick('overdue')}
                >
                  <div className="overdue item">
                    <span className="count">{counts.safety_overdue_count}</span>
                    <span className="label">Overdue</span>
                  </div>
                </div>
                <div
                  className="clickable-section"
                  onClick={() => handleClick('dueSoon')}
                >
                  <div className="due-soon item">
                    <span className="count">
                      {counts.safety_due_soon_count}
                    </span>
                    <span className="label">Due Soon</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default SafetyInspectionsCountWidget;
