const gmrv = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return [231, 525, 315, 233, 314, 313, 327, 833];
  }

  return [511, 822];
};

const cardinalCourier = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return [682, 374, 1157];
  }

  return [984, 614, 1157];
};

const motiveFleets = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return [1194];
  }
  //Just Cardinal on staging as a test
  return [614];
};

const algoInsightsFleets = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return [1147, 1184];
  }

  return [614];
};

const roiReportFleets = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return [374, 730, 665];
  }

  return [614];
};

const isMultipleTrips = () => {
  if (process.env.REACT_APP_ENV === 'production') return [668, 226];

  return [822];
};

const npl = () => {
  if (process.env.REACT_APP_ENV === 'production') return [395];

  return [700];
};

const wolfline = () => {
  if (process.env.REACT_APP_ENV === 'production') return [933];

  return [];
};

const bmc = () => {
  if (process.env.REACT_APP_ENV === 'production') return [1074];

  return [];
};

const renzi = () => {
  if (process.env.REACT_APP_ENV === 'production') return [924];

  return [614];
};

const summitMaterials = () => {
  if (process.env.REACT_APP_ENV === 'production')
    return [1259, 1260, 1261, 1262, 1263, 1264];

  return [];
};

const summitMaterialsWest = () => {
  if (process.env.REACT_APP_ENV === 'production')
    return [1361, 1429, 1430];

  return [];
};

const cityOfWestJordan = () => {
  if (process.env.REACT_APP_ENV === 'production') return [1184];

  return [];
};

const foxAndJames = () => {
  if (process.env.REACT_APP_ENV === 'production')
    return [1147, 1163, 1164, 1165, 1166, 1167, 1168];

  return [];
};

const goBolt = () => {
  if (process.env.REACT_APP_ENV === 'production') return [1567];

  return [];
};

const foodExpress = () => {
  if (process.env.REACT_APP_ENV === 'production')
    return [1251, 1250, 1252, 1241, 1245, 1247, 1249, 1246, 1253];

  return [];
};

const ufpFleet = () => {
  console.log('environment:', process.env.REACT_APP_ENV);
  if (process.env.REACT_APP_ENV === 'production') return [1283, 1460, 1472, 1473, 1474, 1475, 1476, 1477, 1478, 1494, 1424];

  // 1157 = Wright Beverage Test Data on staging
  return [1283, 1460, 1157];
};

const tantara = () => {
  if (process.env.REACT_APP_ENV === 'production') return [1222];

  return [];
};

const searportIntermodal = () => {
  if (process.env.REACT_APP_ENV === 'production') return [1283, 1157, 374];

  return [1157];
};

const foodExpressVancouver = () => {
  if (process.env.REACT_APP_ENV === 'production') return [1245];

  return [1245];
};

const customFleets = {
  gmrv: gmrv(),
  cardinalCourier: cardinalCourier(),
  roiReportFleets: roiReportFleets(),
  foodExpressVancouver: foodExpressVancouver(),
  isMultipleTrips: isMultipleTrips(),
  npl: npl(),
  wolfline: wolfline(),
  bmc: bmc(),
  renzi: renzi(),
  summitMaterials: summitMaterials(),
  summitMaterialsWest: summitMaterialsWest(),
  foodExpress: foodExpress(),
  ufpFleet: ufpFleet(),
  tantara: tantara(),
  searportIntermodal: searportIntermodal(),
  cityOfWestJordan: cityOfWestJordan(),
  foxAndJames: foxAndJames(),
  motiveFleets: motiveFleets(),
  algoInsightsFleets: algoInsightsFleets(),
  goBolt: goBolt(),
};

export default customFleets;
