import React, { useState, useEffect } from 'react';
import {
  Radio,
  Input,
  Form,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
  Tooltip,
  notification,
  message,
  Checkbox,
} from 'antd';
import styled from 'styled-components';
import { webServiceProvider } from 'shared';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CarStore } from 'stores';
import { CurrentUserStore } from 'stores';
import ShopStore from '../../stores/ShopStore';
import moment from 'moment';
import ServiceName from './ServiceName';
const { Option } = Select;

const SetupDetailsHeader = styled.h2`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  justify-content: flex-start;
  padding-bottom: 30px;

  &::before {
    content: '';
    width: 30px;
    margin-right: 10px;
    height: 1px;
    background-color: #4d79ff;
  }

  &::after {
    content: '';
    flex-grow: 1;
    margin-left: 10px;
    height: 1px;
    background-color: #4d79ff;
  }
`;

const RadioGroupWithBorders = styled(Radio.Group)`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .ant-radio-wrapper {
    flex: 1;
    text-align: left;
    transition: border-bottom-color 0.3s;
  }

  .ant-radio-wrapper:hover .ant-radio-inner {
    border-color: #4d79ff;
  }

  .ant-radio-wrapper.ant-radio-wrapper-checked {
    border-bottom-color: transparent;
  }
`;

const CheckboxGroupWithBorders = styled(Checkbox.Group)`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .ant-checkbox-wrapper {
    text-align: left;
    transition: border-bottom-color 0.3s;
    width: 100%;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #4d79ff;
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    border-bottom-color: transparent;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const CustomErrorMessage = styled.div`
  color: red;
  margin-bottom: 16px;
  margin-top: -20px;
  text-align: left;
`;

const HiddenRow = styled(Row)`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const BlueLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ isActivee }) => (isActivee ? '#4d79ff' : '#D9D9D9')};
  margin-top: 8px;
  margin-bottom: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-top: 20px;
`;

const IntervalsRow = styled(Row)`
  width: 100%;
`;

const RULE_TYPE_COMBINATIONS = {
  'time,mileage': 'time_or_mileage',
  'mileage,time': 'time_or_mileage',
  'time,engine_hours': 'time_or_engine_hours',
  'engine_hours,time': 'time_or_engine_hours',
  'mileage,engine_hours': 'mileage_or_engine_hours',
  'engine_hours,mileage': 'mileage_or_engine_hours',
};

const IntervalSelector = ({ form, onCancel, initialValues, report }) => {
  const [thresholds, setThresholds] = useState({
    overdue: false,
    dueSoon: false,
  });

  const userSettings = CurrentUserStore.user.settings;
  const mileOrkm = userSettings?.odometer === 'km' ? 'km' : 'mi';

  // const isEditMode = Boolean(initialValues?.routineInfo);
  const [isEditMode] = useState(Boolean(initialValues?.routineInfo) || false);
  const [serviceHash] = useState(initialValues?.hash);
  const [serviceType] = useState(
    initialValues?.safety_inspection_flag
      ? 'DOT Inspection'
      : 'Preventative Maintenance (PM)'
  );

  const [selectedInterval, setSelectedInterval] = useState(
    initialValues?.rule_type || 'time'
  );

  // Convert KM to Miles (1 KM = 0.621371 Miles)
  const kmToMiles = (km) => (km ? Math.round(km * 0.621371) : 1);
  // Convert seconds to rounded hours
  const secondsToHours = (seconds) =>
    seconds ? Math.round(seconds / 3600) : 1;

  // Initialize mileage in miles
  const [startedMileage, setStartedMileage] = useState(
    kmToMiles(initialValues?.routineInfo?.started_mileage)
  );
  const [intervalMileage, setIntervalMileage] = useState(
    kmToMiles(initialValues?.routineInfo?.interval_mileage)
  );
  const [startedMileageActual, setStartedMileageActual] = useState(
    secondsToHours(initialValues?.routineInfo?.started_mileage)
  );
  // Initialize engine hours
  const [startedEngineHours, setStartedEngineHours] = useState(
    secondsToHours(initialValues?.routineInfo?.started_engine_hours)
  );
  const [startedEngineHoursActual, setStartedEngineHoursActual] = useState(
    secondsToHours(initialValues?.routineInfo?.started_engine_hours)
  );
  const [intervalEngineHours, setIntervalEngineHours] = useState(
    initialValues?.routineInfo?.interval_engine_hours || 1
  );

  const [startedTime, setStartedTime] = useState(
    initialValues?.routineInfo?.started_time
      ? moment.unix(initialValues.routineInfo.started_time)
      : null
  );

  const [repeatInterval, setRepeatInterval] = useState(
    initialValues?.routineInfo?.interval_month || 1
  );

  const [assetType, setAssetType] = useState(
    initialValues?.car?.carType?.toLowerCase() === 'trailer'
      ? 'Trailer'
      : 'Vehicle'
  );

  const [assets, setAssets] = useState([]); // Holds the assets to be displayed in the dropdown
  const [selectedAsset, setSelectedAsset] = useState(
    initialValues?.car?.id || null
  );
  // Fetch assets based on the selected asset type
  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const shopId = ShopStore.currentShop.id;
        const { result, reportData } = await CarStore.getShopCarsBasicInfo(
          shopId
        );

        // Filter assets based on the selected asset type
        const filteredAssets = result.filter(
          (asset) => asset.carType === assetType.toLowerCase()
        );
        // Set thresholds based on reportData.actions
        if (reportData && reportData.actions) {
          setThresholds({
            overdue: reportData.actions.includes('O'), // "O" for Overdue
            dueSoon: reportData.actions.includes('D'), // "D" for Due Soon
          });
        }
        setAssets(filteredAssets); // Update the assets dropdown
      } catch (error) {
        console.error('Error fetching assets', error);
      }
    };

    fetchAssets();
  }, [assetType]); // Re-run the effect when assetType changes

  // on change assets, check if we have a query param for carId
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const carId = urlParams.get('carId');
    const carType = urlParams.get('carType');
    if (assets.length > 0 && carId) {
      setTimeout(() => {
        if (carType) {
          setAssetType(carType === 'trailer' ? 'Trailer' : 'Vehicle');
        }
        handleAssetChange(+carId);
      }, 500);
    }
  }, [assets]);

  const handleAssetTypeChange = (value) => {
    setAssetType(value);
    setSelectedAsset(null);
    form.setFieldsValue({ asset: undefined });
  };

  const handleIntervalChange = (e) => {
    console.log(e);
    // setSelectedInterval(e.target.value);
  };

  const handleRepeatIntervalChange = (e) => {
    setRepeatInterval(e.target.value);
  };

  const handleStartedTimeChange = (date) => {
    setStartedTime(date);
  };

  const handleStartedMileageChange = (e) => {
    setStartedMileage(e.target.value);
  };

  const handleIntervalMileageChange = (e) => {
    setIntervalMileage(e.target.value);
  };

  const handleStartedEngineHoursChange = (e) => {
    setStartedEngineHours(e.target.value);
  };

  const handleIntervalEngineHoursChange = (e) => {
    setIntervalEngineHours(e.target.value);
  };

  const handleAssetChange = (value) => {
    setSelectedAsset(value); // Update selected asset dynamically

    // Find the selected asset from the assets list
    const selectedAssetData = assets.find((asset) => asset.id === value);

    // If the user is not in edit mode, set the starting mileage from the asset's totalMileage
    if (!isEditMode && selectedAssetData) {
      const userSettings = CurrentUserStore.user.settings;
      const mileageFinal =
        userSettings?.odometer === 'km'
          ? Math.round(selectedAssetData.totalMileage)
          : kmToMiles(Math.round(selectedAssetData.totalMileage)); // Convert km to miles if needed
      const engineHours = Math.round(
        selectedAssetData.engineHourInSeconds / 3600
      ); // Convert seconds to hours
      form.setFieldsValue({
        startingMileage: mileageFinal,
        startingEngineHours: engineHours,
      }); // Set the starting mileage and engine hours in the form
      setStartedMileage(mileageFinal); // Update the mileage state
      setStartedEngineHours(engineHours); // Update the engine hours state
      setStartedEngineHoursActual(engineHours);
      setStartedMileageActual(mileageFinal);
    }
  };

  const handleThresholdChange = (e) => {
    setThresholds({
      ...thresholds,
      [e.target.name]: e.target.checked,
    });
  };

  const createRuleType = (intervalArray) => {
    if (!Array.isArray(intervalArray)) {
      throw new Error('Input must be an array');
    }

    if (intervalArray.length === 1) {
      return intervalArray[0];
    }

    if (intervalArray.length === 2) {
      const key = intervalArray.join(',');
      const ruleType = RULE_TYPE_COMBINATIONS[key];

      if (ruleType) {
        return ruleType;
      }
    }

    throw new Error('Invalid input array');
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let hideLoadingMessage = null; // Store reference for closing the loading message

    try {
      const values = await new Promise((resolve, reject) => {
        form.validateFields((err, values) => {
          if (err) {
            reject(err);
          } else {
            resolve(values);
          }
        });
      });

      // Show loading message (progress dialog)
      hideLoadingMessage = message.loading('Processing your request...', 0); // 0 means it will stay open until we close it manually

      // Determine the item and action values based on the service type
      const itemValue =
        values.serviceType === 'PM' ? 'PM' : 'DOT Inspection';
      const isSafetyInspection = values.serviceType === 'PM' ? false : true;
      const actionValue = values.serviceName;
      const finalEngineHours =
        Math.round(startedEngineHours * 3600) >
        Math.round(startedEngineHoursActual * 3600)
          ? Math.round(startedEngineHours * 3600) -
            Math.round(startedEngineHoursActual * 3600)
          : Math.round(startedEngineHours * 3600);

      const userSettings = CurrentUserStore.user.settings;
      const finalStartedMileage =
        userSettings?.odometer === 'km'
          ? Math.round(startedMileage)
          : Math.round(startedMileage * 1.60934);
      const finalMileageInterval =
        userSettings?.odometer === 'km'
          ? Math.round(intervalMileage)
          : Math.round(intervalMileage * 1.60934);
      const finalStartedMileageActual =
        userSettings?.odometer === 'km'
          ? Math.round(startedMileageActual)
          : Math.round(startedMileageActual * 1.60934);
      // Construct the object based on form values
      const generatedObject = {
        shopId: ShopStore.currentShop.id, // assuming you want to use the current shop's id
        data: {
          isSafetyInspection,
          action: actionValue, // Conditionally set to Remind for PM or Inspect for others
          item: itemValue, // Conditionally set to PM or DOT Inspection
          description: values.description || '',
          priority: 1,
          status: 'new',
          actualCarInfo: [
            {
              carId: selectedAsset,
              ...(values['checkbox-group'].includes('mileage') && {
                mileage: finalStartedMileageActual,
              }),
              ...(values['checkbox-group'].includes('engine_hours') && {
                engineHours: Math.round(startedEngineHoursActual * 3600),
              }),
              ...(values['checkbox-group'].includes('time') && {
                time: new Date().toISOString(),
              }),
            },
          ],
          rule_type: createRuleType(values['checkbox-group']), // time, mileage, or engine_hours based on user selection
          interval_time: values['checkbox-group'].includes('time')
            ? Math.round(repeatInterval * 2592000)
            : null, // Convert months to seconds (1 month = 30 days) and round
          started_time:
            values['checkbox-group'].includes('time') && startedTime
              ? startedTime.unix()
              : null, // Unix timestamp for time interval
          interval_mileage: values['checkbox-group'].includes('mileage')
            ? finalMileageInterval
            : null,
          started_mileage: values['checkbox-group'].includes('mileage')
            ? finalStartedMileage
            : null,
          interval_engine_hours: values['checkbox-group'].includes(
            'engine_hours'
          )
            ? Math.round(intervalEngineHours * 3600)
            : null,
          started_engine_hours: values['checkbox-group'].includes(
            'engine_hours'
          )
            ? Math.round(finalEngineHours)
            : null, // Convert hours to seconds and round
          threshold: {
            time: values['checkbox-group'].includes('time') ? 1 : null,
            mileage: values['checkbox-group'].includes('mileage') ? 1 : null,
            engineHours: values['checkbox-group'].includes('engine_hours')
              ? 3600
              : null,
          },
        },
        carIds: [selectedAsset],
      };

      if (isEditMode) {
        generatedObject.issue = { hash: serviceHash };
        if (selectedInterval === 'mileage') {
          generatedObject.issue.car = {
            mileageTotal: Math.round(startedMileage * 1.60934),
          };
        }
      }

      // Send the request with the generated object
      await webServiceProvider.post('v1/car/newPm', generatedObject);

      // Close the loading message
      if (hideLoadingMessage) hideLoadingMessage();

      // Show success notification
      notification.success({
        message: 'Service Added Successfully',
        description:
          'The Preventative Maintenance or DOT Inspection has been successfully added.',
      });

      // Reset the form fields
      form.resetFields();

      // Call the onCancel function to close or navigate away
      if (onCancel) {
        onCancel();
      }
    } catch (error) {
      // Close the loading message
      if (hideLoadingMessage) hideLoadingMessage();

      // Extract the error message from the API response
      const errorMessage =
        error?.response?.data?.message ||
        error.message ||
        'There was an issue processing your request. Please try again.';

      // Show error notification with actual error message
      notification.error({
        message: 'Error',
        description: errorMessage,
      });

      console.log('Validation or submission error:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      {/* Styled header with line */}
      <SetupDetailsHeader>Setup Details</SetupDetailsHeader>

      <Form layout="vertical" onSubmit={onSubmit}>
        {/* Service Type, Service Name, and Asset */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label={<span style={{ fontWeight: 'bold' }}>Service Type:</span>}
            >
              {form.getFieldDecorator('serviceType', {
                // initialValue: serviceType,
                rules: [
                  { required: true, message: 'Service type is required' },
                ],
              })(
                <Select>
                  <Option value="PM">Preventative Maintenance (PM)</Option>
                  <Option value="DOT Inspection">DOT Inspection</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <ServiceName form={form} initialValues={initialValues} />
          </Col>
          <Col span={8}>
            <Form.Item
              label={<span style={{ fontWeight: 'bold' }}>Asset Type:</span>}
            >
              {form.getFieldDecorator('assetType', {
                initialValue: assetType,
                rules: [{ required: true, message: 'Asset type is required' }],
              })(
                <Select onChange={handleAssetTypeChange}>
                  <Option value="Vehicle">Vehicle</Option>
                  <Option value="Trailer">Trailer</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label={<span style={{ fontWeight: 'bold' }}>Select Asset:</span>}
            >
              {form.getFieldDecorator('asset', {
                initialValue: selectedAsset,
                rules: [{ required: true, message: 'Please select an asset' }],
              })(
                <Select
                  showSearch
                  placeholder="Select an asset"
                  onChange={handleAssetChange}
                  value={selectedAsset}
                  filterOption={(input, option) => {
                    /* console.log('Search Input:', input);
                  console.log('Option:', option);
                  console.log('Option children:', option?.children);
                  console.log('Option value:', option?.value);
                  console.log('Option label:', option?.label);*/

                    // Try accessing the text content
                    const searchText = `${option?.props?.children}`.toLowerCase();
                    // console.log('Search text:', searchText);

                    return searchText.includes(input.toLowerCase());
                  }}
                  optionFilterProp="children"
                >
                  {assets.map((asset) => (
                    <Option key={asset.id} value={asset.id}>
                      {`${asset.unitId} - ${asset.vin}`}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        {/* Description */}
        <Form.Item
          label={<span style={{ fontWeight: 'bold' }}>Description:</span>}
        >
          {form.getFieldDecorator('description', {
            initialValue: initialValues?.description || '',
          })(
            <Input.TextArea
              placeholder={`Add description such as all service tasks, e.g.:\n1. Oil and filter changes,\n2. Brake inspections and adjustments,\n3. Fluid level checks,\n4. etc.`}
              rows={5}
            />
          )}
        </Form.Item>

        {/* Interval Selection */}
        <Row gutter={16}>
          <Col span={24}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '20px',
              }}
            >
              <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
              <span
                style={{
                  fontWeight: 'bold',
                  marginRight: '8px',
                  marginLeft: '8px',
                }}
              >
                Set the service reminder (Select up to 2 reminders e.g., time &
                mileage):
              </span>
              <Tooltip title="Set the service interval based on time, mileage, or engine hours to receive timely reminders and keep your fleet running smoothly">
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
            {form.getFieldError('checkbox-group')?.length > 0 && (
              <CustomErrorMessage>
                {form.getFieldError('checkbox-group')[0]}
              </CustomErrorMessage>
            )}
            <StyledFormItem help={''} hasFeedback={false}>
              {form.getFieldDecorator('checkbox-group', {
                initialValue: [],
                rules: [
                  {
                    required: true,
                    message: 'Please select an interval base',
                  },
                ],
              })(
                <CheckboxGroupWithBorders
                  value={selectedInterval}
                  onChange={handleIntervalChange}
                >
                  <IntervalsRow gutter={16}>
                    <Col span={8}>
                      <Checkbox
                        value="time"
                        disabled={
                          form.getFieldValue('checkbox-group').length >= 2 &&
                          !form.getFieldValue('checkbox-group').includes('time')
                        }
                      >
                        Time
                        {
                          <>
                            <BlueLine
                              isActivee={form
                                .getFieldValue('checkbox-group')
                                .includes('time')}
                            />
                            <div className="interval-details">
                              <HiddenRow
                                gutter={16}
                                isVisible={form
                                  .getFieldValue('checkbox-group')
                                  .includes('time')}
                              >
                                <Col span={10}>
                                  <StyledFormItem
                                    label={
                                      <span style={{ fontWeight: 'bold' }}>
                                        Service Start On:
                                      </span>
                                    }
                                  >
                                    {form.getFieldDecorator('serviceStartOn', {
                                      initialValue: startedTime,
                                      rules: [
                                        {
                                          required: form
                                            .getFieldValue('checkbox-group')
                                            .includes('time'),
                                          message: 'Please select a start date',
                                        },
                                      ],
                                    })(
                                      <DatePicker
                                        style={{ width: '100%' }}
                                        value={startedTime}
                                        onChange={handleStartedTimeChange}
                                      />
                                    )}
                                  </StyledFormItem>
                                </Col>
                                <Col span={14}>
                                  <StyledFormItem
                                    label={
                                      <span style={{ fontWeight: 'bold' }}>
                                        Repeat Service Every:
                                      </span>
                                    }
                                  >
                                    <Input.Group compact>
                                      <Input
                                        type="number"
                                        min={1}
                                        value={repeatInterval}
                                        onChange={handleRepeatIntervalChange}
                                        style={{
                                          width: '80px',
                                          textAlign: 'center',
                                        }}
                                      />
                                      <Select value="Months">
                                        <Option value="Months">Months</Option>
                                      </Select>
                                    </Input.Group>
                                  </StyledFormItem>
                                </Col>
                              </HiddenRow>
                            </div>
                          </>
                        }
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox
                        value="mileage"
                        disabled={
                          form.getFieldValue('checkbox-group').length >= 2 &&
                          !form
                            .getFieldValue('checkbox-group')
                            .includes('mileage')
                        }
                      >
                        Mileage
                        {
                          <>
                            <BlueLine
                              isActivee={form
                                .getFieldValue('checkbox-group')
                                .includes('mileage')}
                            />
                            <div className="interval-details">
                              <HiddenRow
                                gutter={16}
                                isVisible={form
                                  .getFieldValue('checkbox-group')
                                  .includes('mileage')}
                              >
                                <Col span={12}>
                                  <StyledFormItem
                                    label={
                                      <span style={{ fontWeight: 'bold' }}>
                                        Starting Mileage:
                                      </span>
                                    }
                                  >
                                    {form.getFieldDecorator('startingMileage', {
                                      initialValue: startedMileage,
                                      rules: [
                                        {
                                          required: form
                                            .getFieldValue('checkbox-group')
                                            .includes('mileage'),
                                          message:
                                            'Please enter starting mileage',
                                        },
                                      ],
                                    })(
                                      <Input
                                        type="number"
                                        min={0}
                                        value={startedMileage}
                                        onChange={handleStartedMileageChange}
                                        addonAfter={mileOrkm}
                                      />
                                    )}
                                  </StyledFormItem>
                                </Col>
                                <Col span={12}>
                                  <StyledFormItem
                                    label={
                                      <span style={{ fontWeight: 'bold' }}>
                                        Repeat Mileage Every:
                                      </span>
                                    }
                                  >
                                    {form.getFieldDecorator('intervalMileage', {
                                      initialValue: intervalMileage,
                                      rules: [
                                        {
                                          required: form
                                            .getFieldValue('checkbox-group')
                                            .includes('mileage'),
                                          message:
                                            'Please enter interval mileage',
                                        },
                                      ],
                                    })(
                                      <Input
                                        type="number"
                                        min={0}
                                        value={intervalMileage}
                                        onChange={handleIntervalMileageChange}
                                        addonAfter={mileOrkm}
                                      />
                                    )}
                                  </StyledFormItem>
                                </Col>
                              </HiddenRow>
                            </div>
                          </>
                        }
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox
                        value="engine_hours"
                        disabled={
                          form.getFieldValue('checkbox-group').length >= 2 &&
                          !form
                            .getFieldValue('checkbox-group')
                            .includes('engine_hours')
                        }
                      >
                        Engine Hours
                        {
                          <>
                            <BlueLine
                              isActivee={form
                                .getFieldValue('checkbox-group')
                                .includes('engine_hours')}
                            />
                            <div className="interval-details">
                              <HiddenRow
                                gutter={16}
                                isVisible={form
                                  .getFieldValue('checkbox-group')
                                  .includes('engine_hours')}
                              >
                                <Col span={12}>
                                  <StyledFormItem
                                    label={
                                      <span style={{ fontWeight: 'bold' }}>
                                        Starting Engine Hours:
                                      </span>
                                    }
                                  >
                                    {form.getFieldDecorator(
                                      'startingEngineHours',
                                      {
                                        initialValue: startedEngineHours,
                                        rules: [
                                          {
                                            required: form
                                              .getFieldValue('checkbox-group')
                                              .includes('engine_hours'),
                                            message:
                                              'Please enter starting engine hours',
                                          },
                                        ],
                                      }
                                    )(
                                      <Input
                                        type="number"
                                        min={0}
                                        value={startedEngineHours}
                                        onChange={
                                          handleStartedEngineHoursChange
                                        }
                                        addonAfter="hrs"
                                      />
                                    )}
                                  </StyledFormItem>
                                </Col>
                                <Col span={12}>
                                  <StyledFormItem
                                    label={
                                      <span style={{ fontWeight: 'bold' }}>
                                        Repeat Engine Hours Every:
                                      </span>
                                    }
                                  >
                                    {form.getFieldDecorator(
                                      'intervalEngineHours',
                                      {
                                        initialValue: intervalEngineHours,
                                        rules: [
                                          {
                                            required: form
                                              .getFieldValue('checkbox-group')
                                              .includes('engine_hours'),
                                            message:
                                              'Please enter interval engine hours',
                                          },
                                        ],
                                      }
                                    )(
                                      <Input
                                        type="number"
                                        min={0}
                                        value={intervalEngineHours}
                                        onChange={
                                          handleIntervalEngineHoursChange
                                        }
                                        addonAfter="hrs"
                                      />
                                    )}
                                  </StyledFormItem>
                                </Col>
                              </HiddenRow>
                            </div>
                          </>
                        }
                      </Checkbox>
                    </Col>
                  </IntervalsRow>
                </CheckboxGroupWithBorders>
              )}
            </StyledFormItem>
          </Col>
        </Row>
        <ServiceIntervalConfirmation
          time={
            form.getFieldValue('checkbox-group').includes('time') &&
            startedTime &&
            repeatInterval
          }
          mileage={
            form.getFieldValue('checkbox-group').includes('mileage') &&
            intervalMileage
          }
          hours={
            form.getFieldValue('checkbox-group').includes('engine_hours') &&
            intervalEngineHours
          }
        />

        {/* Notification Thresholds */}
        {/* <Row gutter={16}>
          <Col span={24}>
            <span style={{ fontWeight: 'bold', display: 'block', marginBottom: '8px', textAlign: 'left' }}>
              Set what threshold to be notified at:
            </span>
            <Form.Item style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-start' }}>
              <Checkbox
                checked={thresholds.overdue}
                onChange={handleThresholdChange}
                name="overdue"
                style={{ marginRight: '20px' }}
              >
                Overdue
              </Checkbox>
              <Checkbox
                checked={thresholds.dueSoon}
                onChange={handleThresholdChange}
                name="dueSoon"
              >
                Due Soon
              </Checkbox>
            </Form.Item>
          </Col>
        </Row> */}

        {/* Action Buttons */}
        <ButtonGroup>
          <Button
            type="link"
            danger
            style={{ color: 'red' }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Add Service
          </Button>
        </ButtonGroup>
      </Form>
    </div>
  );
};

const WrappedIntervalSelector = Form.create()(IntervalSelector);

export default WrappedIntervalSelector;

const StyledConfirmation = styled.div`
  text-align: left;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
`;

const HighlightedText = styled.span`
  text-decoration: underline;
`;

const ServiceIntervalConfirmation = ({ time, mileage, hours }) => {
  const intervals = [];
  if (time) intervals.push(`${time} month${+time > 1 ? 's' : ''}`);
  if (mileage) intervals.push(`${mileage} mile${+mileage > 1 ? 's' : ''}`);
  if (hours) intervals.push(`${hours} hour${+hours > 1 ? 's' : ''}`);

  if (intervals.length === 1) {
    return (
      <StyledConfirmation>
        Confirmation: Your service interval will repeat every {intervals[0]}
      </StyledConfirmation>
    );
  } else if (intervals.length === 2) {
    return (
      <StyledConfirmation>
        Confirmation: Your service interval will repeat every {intervals[0]}{' '}
        <HighlightedText>OR</HighlightedText> every {intervals[1]}
      </StyledConfirmation>
    );
  }

  return null;
};
