import React, { Component } from 'react';
import { Tabs } from 'antd';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import FleetCostsPage from '../FleetCosts/FleetCostsPage'; // Adjust the path as needed
import FleetRepeatVisitsPage from '../FleetRepeatVisits/FleetRepeatVisitsPage.js'; // Adjust the path as needed
import FleetAvgCostPerVisitPage from '../FleetAvgCostPerVisit/FleetAvgCostPerVisitPage.js'; // Adjust the path as needed
import FleetPMQualityPage from '../FleetPMQuality/FleetPMQualityPage.js'; // Adjust the path as needed
import AssetDurationPage from '../AssetDuration/AssetDurationPage.js';
import AssetAvailabilityPage from '../AssetAvailability/AssetAvailability.js';
import AppStore from 'stores/App.js';

const { TabPane } = Tabs;

const Container = styled.div`
  max-width: 1300px; // Adjust the width as needed
  margin: 0 auto; // Center the container
  padding: 20px; // Add padding if necessary
`;

class FleetReportsPage extends Component {
  componentDidMount() {
    AppStore.setSelectedSidebarKey('/fleet-reports');
  }

  render() {
    return (
      <Container>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Fleet Costs" key="1">
            <FleetCostsPage />
          </TabPane>
          <TabPane tab="Fleet Repeat Visits" key="2">
            <FleetRepeatVisitsPage />
          </TabPane>
          <TabPane tab="Fleet Avg Cost Per Visit" key="3">
            <FleetAvgCostPerVisitPage />
          </TabPane>
          <TabPane tab="Fleet PM Quality" key="4">
            <FleetPMQualityPage />
          </TabPane>
          <TabPane tab="Asset Status Duration" key="5">
            <AssetDurationPage />
          </TabPane>
          <TabPane tab="Asset Availability" key="6">
            <AssetAvailabilityPage />
          </TabPane>
        </Tabs>
      </Container>
    );
  }
}

export default withRouter(observer(FleetReportsPage));