import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { DealershipChooser } from 'components';
import { Form, Select, InputNumber, Input, Button, Card, Col, Row, Spin, notification, Popover, Typography } from 'antd';
import styled from 'styled-components';
import { observe } from 'mobx';
import { CarStatusStore, CarStore, ContactStore, ShopStore } from 'stores';
import { get } from 'lodash';
import { Icon } from 'antd';

import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PitstopSelect from 'components/Forms/PitstopSelect';

const { Option } = Select;

const H2Styled = styled.h2`
  font-size: 1.2rem;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .ant-input {
    margin-right: 20px;
  }

  .search-icon {
    font-size: 16px;
  }
`;

class AssetDurationPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  state = {
    isLoadingStatuses: false,
    isLoadingAssetDuration: false,
    assetDurationData: [],
    timePeriod: 7,
    limit: 20,
    status: ['running'],
    selectedStatusDescription: '',
    searchText: '', // State for storing search input
    isLoadingInitialFilterData: false,
    filter: {
      status: ['running'],
      vendor: undefined,
      make: undefined,
      model: undefined,
      year: undefined,
      carIds: undefined,
    },
    shopContacts: [],
    shopStatuses: [],
    shopMakes: [],
    shopModels: [],
    shopYears: [],
    shopCars: [],
  };

  async componentDidMount() {
    await Promise.all([
      this.loadInitialFilterData(),
      this.loadDataForAssetDuration(),
    ]);
  }

  componentWillUnmount() {
    if (this.disposer) {
      this.disposer();
    }
  }

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    await Promise.all([
      this.loadInitialFilterData(),
      this.loadDataForAssetDuration(),
    ]);
  });

  loadInitialFilterData = async () => {
    this.setState({ isLoadingInitialFilterData: true });
    const [
      shopContacts,
      shopStatuses,
      shopMakes,
      shopModels,
      shopYears,
      shopCars,
    ] = await Promise.all([
      ContactStore.getShopContacts(ShopStore.currentShop.id),
      CarStatusStore.fetchShopCarStatuses(ShopStore.currentShop.id),
      ShopStore.getShopMakes(ShopStore.currentShop.id),
      ShopStore.getShopModels(ShopStore.currentShop.id),
      ShopStore.getShopYears(ShopStore.currentShop.id),
      CarStore.getShopCarsBasicInfoAndReturn(ShopStore.currentShop.id),
    ]);
    this.setState({
      shopContacts: shopContacts || [],
      shopStatuses: shopStatuses || [],
      shopMakes: shopMakes || [],
      shopModels: shopModels || [],
      shopYears: shopYears || [],
      shopCars: shopCars || [],
      isLoadingInitialFilterData: false,
    });
  };

  loadDataForAssetDuration = async () => {
    try {
      this.setState({ isLoadingAssetDuration: true });
      const {
        timePeriod,
        limit,
        searchText,
        filter: {
          status,
          vendor,
          make,
          model,
          year,
          carIds,
        },
      } = this.state;

      const response = await ShopStore.getAssetDurationData(
        ShopStore.currentShop.id,
        timePeriod,
        limit,
        status,
        searchText,
        {
          vendor: vendor,
          make: make,
          model: model,
          year: year,
          carIds: carIds,
        },
      );

      const data = get(response, 'data.assets', []);

      if (Array.isArray(data) && data.length > 0) {
        this.setState({
          assetDurationData: data,
        });
      } else {
        notification.warning({
          message: 'No Data',
          description: 'No asset duration data available for the selected filters.',
        });
        this.setState({ assetDurationData: [] });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
      this.setState({ assetDurationData: [] });
    } finally {
      this.setState({ isLoadingAssetDuration: false });
    }
  };

  handleFilterChange = (changedFields) => {
    if (changedFields.filter && changedFields.filter.status && Array.isArray(changedFields.filter.status)) {
      const selectedStatuses = this.state.shopStatuses.filter(
        (status) => changedFields.filter.status.includes(status.key)
      );
      this.setState({
        ...changedFields,
        selectedStatusDescription: selectedStatuses.map((status) => status.description).join(', '),
      });
    } else {
      this.setState(changedFields);
    }
  };

  handleSearchTextChange = (e) => {
    this.setState({ searchText: e.target.value }, () => {
      this.loadDataForAssetDuration(); // Automatically call API when search text changes
    });
  };

  handleApplyFilters = () => {
    this.loadDataForAssetDuration();
  };

  handleResetFilters = () => {
    this.setState({
      filter: {
        status: ['running'],
        vendor: undefined,
        make: undefined,
        model: undefined,
        year: undefined,
        carIds: undefined,
      },
      status: ['running'],
      selectedStatusDescription: '',
    }, () => {
      this.loadDataForAssetDuration();
    });
  };

  render() {
    const {
      assetDurationData,
      timePeriod,
      limit,
      shopStatuses,
      isLoadingStatuses,
      isLoadingAssetDuration,
      selectedStatusDescription,
      searchText,
      filter: {
        status,
        vendor,
        make,
        model,
        year,
        carIds,
      },
    } = this.state;

    let statusToDisplay = '';
    if (Array.isArray(status)) {
      statusToDisplay = status.join(',');
    } else if (!status) {
      statusToDisplay = '';
    } else {
      statusToDisplay = status.replace('-', ' ');
    }

    const assetDurationChartOptions = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: assetDurationData.map(item => `${item.asset_name} (${item.asset_type})`),
        crosshair: true,
        title: {
          text: 'Unit ID',
        },
      },
      yAxis: {
        min: 0,
        max: Math.max(...assetDurationData.map(item => item.duration_in_days)) + 2,
        title: {
          text: 'No. of Days',
        },
        tickInterval: 1
      },
      plotOptions: {
        column: {
          dataLabels: [{
            enabled: true,
            formatter: function () {
              return this.y;
            },
            style: {
              textAlign: 'center',
              color: '#333333',
              fontWeight: 'bold',
            },
            y: -2
          }, {
            enabled: true,
            formatter: function () {
              const percentage = Math.round((this.y / this.series.userOptions.timePeriod) * 100);
              return `${percentage}%`;
            },
            inside: true,
            verticalAlign: 'top',
            y: 10,
            style: {
              textAlign: 'center',
              color: '#FFFFFF'
            }
          }],
        },
        series: {
          cursor: 'pointer',
          point: {
            events: {
              click: (options) => {
                // Construct the URL dynamically
                const vehicleId = options?.target?.point?.assetId;
                if (vehicleId) {
                  const customUrl = `/car/${vehicleId}/work-orders`;
                  this.props.history.push(customUrl);
                }
              },
            },
          },
        },
      },
      series: [
        {
          name: selectedStatusDescription || statusToDisplay || '',
          data: assetDurationData.map(item => {
            return {
              y: item.duration_in_days,
              assetId: item.asset_id,
              assetType: item.asset_type,
            }
          }),
          color: '#183cbc',
          timePeriod: timePeriod
        },
      ],
    };

    return (
      <Fragment>
        <Row gutter={[8, 8]} justify="space-between" align="middle">
          <Col span={20}>
            <h1>Asset Status Duration</h1>
          </Col>
        </Row>
        <DealershipChooser />
        {isLoadingStatuses ? (
          <Spin spinning={true}>
            <p>Loading statuses...</p>
          </Spin>
        ) : (
          <Form layout="inline" style={{ marginTop: '10px' }} onValuesChange={this.handleFilterChange}>
            <Form.Item label="Select Date Range">
              <Select
                defaultValue={7}
                value={timePeriod}
                onChange={(value) => this.handleFilterChange({ timePeriod: value })}
              >
                <Option value={7}>7 days</Option>
                <Option value={30}>30 days</Option>
                <Option value={90}>90 days</Option>
                <Option value={180}>180 days</Option>
                <Option value={360}>360 days</Option>
              </Select>
            </Form.Item>
            {/* <Form.Item label="Select Status">
              <Select
                value={status}
                onChange={(value) => this.handleFilterChange({ status: value })}
              >
                {shopStatuses.map(({ car_status, status_description }) => (
                  <Option key={car_status} value={car_status}>
                    {status_description}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}
            <Form.Item label="Asset Limit">
              <InputNumber
                min={1}
                defaultValue={20}
                value={limit}
                onChange={(value) => this.handleFilterChange({ limit: value })}
              />
            </Form.Item>
            <Form.Item>
              <Popover
                placement="bottomLeft"
                content={
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Typography.Text>Select Status:</Typography.Text>
                      <PitstopSelect
                        mode='multiple'
                        options={this.state.shopStatuses.map((status) => ({
                          label: status.description,
                          value: status.key,
                        }))}
                        value={status}
                        onChange={(value) => {
                          this.handleFilterChange({
                            filter: {
                              ...this.state.filter,
                              status: value || [],
                            },
                          })
                        }}
                      />
                    </Col>
                    {/* Select Vendor */}
                    <Col span={24}>
                      <Typography.Text>Select Vendor</Typography.Text>
                      <PitstopSelect
                        mode='multiple'
                        options={this.state.shopContacts.map((contact) => ({
                          label: contact.name,
                          value: contact.id,
                        }))}
                        value={vendor}
                        onChange={(value) =>
                          this.handleFilterChange({
                            filter: {
                              ...this.state.filter,
                              vendor: value || [],
                            },
                          })
                        }
                      />
                    </Col>
                    {/* Filter by Make */}
                    <Col span={24}>
                      <Typography.Text>Filter by Make</Typography.Text>
                      <PitstopSelect
                        mode='multiple'
                        options={this.state.shopMakes.map((make) => ({
                          label: make.car_make,
                          value: make.car_make,
                        }))}
                        value={make}
                        onChange={(value) =>
                          this.handleFilterChange({
                            filter: {
                              ...this.state.filter,
                              make: value || [],
                            },
                          })
                        }
                      />
                    </Col>
                    {/* Filter by Model */}
                    <Col span={24}>
                      <Typography.Text>Filter by Model</Typography.Text>
                      <PitstopSelect
                        mode='multiple'
                        options={this.state.shopModels.map((model) => ({
                          label: model.car_model,
                          value: model.car_model,
                        }))}
                        value={model}
                        onChange={(value) =>
                          this.handleFilterChange({
                            filter: {
                              ...this.state.filter,
                              model: value || [],
                            },
                          })
                        }
                      />
                    </Col>
                    {/* Filter by Year */}
                    <Col span={24}>
                      <Typography.Text>Filter by Year</Typography.Text>
                      <PitstopSelect
                        mode='multiple'
                        options={this.state.shopYears.map((year) => ({
                          label: year.car_year,
                          value: year.car_year,
                        }))}
                        value={year}
                        onChange={(value) => {
                          this.handleFilterChange({
                            filter: {
                              ...this.state.filter,
                              year: value || [],
                            },
                          })
                        }}
                      />
                    </Col>
                    {/* Search by Assets */}
                    <Col span={24}>
                      <Typography.Text>Search by Assets</Typography.Text>
                      <PitstopSelect
                        placeholder="Search Asset ID"
                        options={(this.state.shopCars || []).map((car) => ({
                          label: car.unitId,
                          value: car.id,
                        }))}
                        mode="multiple"
                        value={carIds}
                        onChange={(value) =>
                          this.handleFilterChange({
                            filter: {
                              ...this.state.filter,
                              carIds: value || [],
                            },
                          })
                        }
                      />
                    </Col>
                    <Col span={24} style={{ textAlign: 'right' }}>
                      <Button style={{ marginRight: 10 }} onClick={this.handleResetFilters}>
                        Reset
                      </Button>
                      <Button type="primary" onClick={this.handleApplyFilters}>
                        Apply
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <Button type="primary" icon="filter" loading={this.state.isLoadingInitialFilterData}>
                  Filters
                </Button>
              </Popover>
            </Form.Item>
          </Form>
        )}
        <Row style={{ marginTop: '10px' }} gutter={[8, 8]}>
          <Col span={24}>
            <Spin spinning={isLoadingAssetDuration}>
              <Card>
                <Row gutter={[8, 8]} justify="space-between" align="middle">
                  <Col style={{ marginTop: '5px' }} span={4}>
                    <H2Styled>Asset Status Duration</H2Styled>
                  </Col>
                  {/* <Col span={20}>
                    <SearchWrapper>
                        <Input
                          value={searchText}
                          onChange={this.handleSearchTextChange}
                          placeholder="Search Asset ID"
                          suffix={<Icon type="search" className="search-icon" />}
                          style={{ width: '20%' }}
                        />
                    </SearchWrapper>
                  </Col> */}
                </Row>
                <Row gutter={[8, 8]} style={{ marginTop: '10px' }}>
                  <Col span={24}>
                    {assetDurationData.length > 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={assetDurationChartOptions}
                      />
                    ) : (
                      <p>No data available for the selected filters.</p>
                    )}
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default withRouter(observer(AssetDurationPage));
