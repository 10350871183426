import React from 'react';
import { Select } from 'antd';
import type { SelectProps } from 'antd/es/select';
import styled from 'styled-components';

interface StyledSelectProps extends SelectProps<any> {
  customStyle?: string;
}

const StyledSelect = styled(Select)<StyledSelectProps>`
  width: 100%;
  &.ant-select {
    ${props => props.customStyle}
  }
`;

interface SelectOption {
  label: string | React.ReactNode;
  value: string | number;
  disabled?: boolean;
}

interface PitstopSelectProps extends Omit<SelectProps<any>, 'options'> {
  options: SelectOption[];
  customStyle?: string;
}

const defaultFilterOption = (input: string, option: any) => {
  if (option.children) {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  } else if (option.props && option.props.children) {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
  return false;
}

const PitstopSelect: React.FC<PitstopSelectProps> = ({
  options,
  value,
  onChange,
  onSearch = () => {},
  placeholder = '-',
  disabled = false,
  loading = false,
  mode,
  showSearch = true,
  optionFilterProp = 'children',
  filterOption = defaultFilterOption,
  customStyle,
  className,
  ...rest
}) => (
  <StyledSelect<any>
    value={value}
    onChange={onChange}
    onSearch={onSearch}
    placeholder={placeholder}
    disabled={disabled}
    loading={loading}
    mode={mode}
    showSearch={showSearch}
    optionFilterProp={optionFilterProp}
    filterOption={filterOption}
    allowClear={true}
    className={className}
    customStyle={customStyle}
    {...rest}
  >
    {options.map((option) => (
      <Select.Option
        key={option.value}
        value={option.value}
        disabled={option.disabled}
      >
        {option.label}
      </Select.Option>
    ))}
  </StyledSelect>
);

export default PitstopSelect;
