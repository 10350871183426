import { Button, Spin } from 'antd';
import { PropTypes } from 'prop-types';
import React from 'react';
import { customFleets } from 'shared';
import { ShopStore } from 'stores';
import styled from 'styled-components';

const TabWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;

  .ant-btn {
    border: 0;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
  }

  .active {
    border-bottom: 2px solid #1f3eb8;
  }
`;

const VehicleCountBadge = styled.span`
  background-color: #dedede;
  color: #333;
  padding: 0.1rem 0.5rem;
  border-radius: 40px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-left: 10px;
`;

function AssetTypeChooser({
  currentType,
  vehiclesCount,
  trailersCount,
  onChange,
  loading,
  disabled,
}) {
  return (
    <TabWrapper>
      <Button
        onClick={() => onChange('vehicles')}
        className={currentType === 'vehicles' ? 'active' : ''}
        disabled={disabled}
        data-test="vehicles-tab"
      >
        Vehicles{' '}
        <VehicleCountBadge>
          {loading ? <Spin size="small" /> : vehiclesCount}
        </VehicleCountBadge>
      </Button>
      <Button
        onClick={() => onChange('trailers')}
        className={currentType === 'trailers' ? 'active' : ''}
        disabled={disabled}
        data-test="trailers-tab"
      >
        {customFleets.goBolt.includes(ShopStore.currentShop.id) ? 'Chargers' : 'Trailers'}{' '}
        <VehicleCountBadge>
          {loading ? <Spin size="small" /> : trailersCount}
        </VehicleCountBadge>
      </Button>
    </TabWrapper>
  );
}

AssetTypeChooser.propTypes = {
  loading: PropTypes.bool,
  currentType: PropTypes.string,
  onChange: PropTypes.func,
  vehiclesCount: PropTypes.any,
  trailersCount: PropTypes.any,
  disabled: PropTypes.bool,
};

AssetTypeChooser.defaultProps = {
  loading: false,
  currentType: 'vehicles',
  vehiclesCount: 0,
  trailersCount: 0,
  disabled: false,
};

export default AssetTypeChooser;
