import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Progress } from 'antd';
import styled from 'styled-components';
import DocumentUploadList from './DocumentUploadList';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    background-color: #02103d;
    color: white;
  }

  .ant-modal-title {
    color: white;
  }

  .ant-modal-close-x {
    color: white;
  }

  .loading-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    background-color: #f0f0f0;
    padding: 0.4rem;
    span {
      font-size: 14px;
      color: #02103d;
    }
  }
`;

const StyledP = styled.p`
  font-size: 14px;
`;

const UploadImageModal = ({
  isVisible,
  onUpload,
  onCancel,
  isLoading,
  isError,
  uploading,
  progress,
}) => {
  const [fileList, setFileList] = useState([]);
  const [currentLoadingMsg, setCurrentLoadingMsg] = useState(
    'Hang tight, we are processing your files...'
  );

  const intervalRef = useRef(null);

  const randomLoadingMessages = [
    'Hang tight, we are processing your files...',
    'Working on your files... almost there!',
  ];

  useEffect(() => {
    if (isVisible) {
      intervalRef.current = setInterval(() => {
        setCurrentLoadingMsg(
          randomLoadingMessages[
            Math.floor(Math.random() * randomLoadingMessages.length)
          ]
        );
      }, 3000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isVisible, randomLoadingMessages]);

  useEffect(() => {
    if (isVisible) {
      setFileList([]);
    }
  }, [isVisible]);

  const handleFileChange = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadProps = {
    accept: '.png,.jpg,.jpeg,.pdf',
    onRemove: (file) => {
      setFileList((curr) => {
        const index = curr.indexOf(file);
        const newFileList = curr.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      setFileList((curr) => [...curr, file]);
      return false; // Prevent automatic uploading
    },
    fileList,
    onChange: handleFileChange,
    multiple: false,
  };

  return (
    <StyledModal
      title="Upload Work Orders - Powered by AI"
      visible={isVisible}
      onOk={() => onUpload(fileList)}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          loading={isLoading}
          disabled={fileList.length === 0}
          key="submit"
          type="primary"
          onClick={() => onUpload(fileList)}
        >
          {isLoading ? 'Processing' : 'Process'}
        </Button>,
      ]}
    >
      <StyledP>
        Upload a single file below, and let Pitstop's AI convert them in digital
        records.
      </StyledP>
      <ul>
        <li>Only English is supported.</li>
        <li>Supported formats are PNG, JPG, PDF (max 5 pages per file).</li>
        <li>Each work order must be a separate file.</li>
        <li>
          Images should be clear, high-res (&gt;250dpi), under 12MB. Avoid
          shadows, skewed pages, or large watermarks.
        </li>
        <li>Handwritten Notes are accepted but not officially supported.</li>
      </ul>
      <StyledP>
        <b>Add Work Orders:</b>
      </StyledP>
      <DocumentUploadList
        isDisabled={fileList.length > 0}
        uploadProps={uploadProps}
      />
      {uploading && (
        <div className="loading-box">
          <Progress percent={progress} />
        </div>
      )}
      {isLoading && (
        <div className="loading-box">
          <span>{currentLoadingMsg}</span>
        </div>
      )}
      {isError && (
        <div className="loading-box">
          <span
            style={{
              color: 'red',
            }}
          >
            Something went wrong. Please try again later.
          </span>
        </div>
      )}
    </StyledModal>
  );
};

UploadImageModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  uploading: PropTypes.bool,
  progress: PropTypes.number,
};

UploadImageModal.defaultProps = {
  isLoading: false,
  isError: false,
  uploading: false,
  progress: 0,
};

export default UploadImageModal;
