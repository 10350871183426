import React, { useContext } from 'react';
import axios from 'axios';
import { Row, Col, DatePicker, InputNumber, Select, Input, Button } from 'antd';
import FormLegend from '../Forms/FormLegend';
import WorkOrderDetailsContext from '../../containers/WorkOrder/WorkOrderDetailsContext';
import ImageUploader from 'containers/WorkOrder/ImageUploadContainer';
import DocumentUploader from 'containers/WorkOrder/DocumentUploader';
import { AppStore, ShopStore } from 'stores';
import { customFleets } from 'shared';
// import SelectContactTable from 'components/Contacts/SelectContactTable';

const equalHeightStyle = {
  minHeight: '321px',
};

const documentUploaderStyle = {
  border: '1px solid #d9d9d9',
  borderRadius: '2px',
  padding: '24px',
  marginTop: '15px',
  marginBottom: '24px',
  ...equalHeightStyle,
  maxHeight: '321px',
  overflow: 'auto',
};

const WorkOrderOptionalInformation = () => {
  const {
    state: { initialFormValues, isDisabled, currentWorkOrder, shopVendors, purchaseOrders },
    setInitialFormValue,
    setImageUploaderContainerRef,
    setDocumentUploaderContainerRef,
    handleImageUpload,
    handleFileUpload,
    onClickImage,
    onClickAddPurchaseOrder,
    handleFormChange
  } = useContext(WorkOrderDetailsContext);

  const filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  const handleDocumentPreview = async (file) => {
    let blob;
    if (file.url) {
      try {
        AppStore.addLoading('Getting document preview');
        // download the file and convert to blob
        const response = await axios.get(file.url, {
          responseType: 'blob',
        });
        // convert file to blob
        blob = new Blob([response.data], {
          type: 'application/pdf',
        });
      } catch (error) {
        console.error('Error getting document preview', error);
        // fallback to open file in new tab
        window.open(file.url, '_blank');
      } finally {
        AppStore.removeLoading('Getting document preview');
      }
    } else {
      // convert file to blob
      blob = new Blob([file.originFileObj], {
        type: file.type,
      });
    }
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = async (e) => {
      const base64 = e.target.result;
      onClickImage(base64);
    };
  };

  const handleChange = () => {
    if (handleFormChange) {
      handleFormChange()
    }
  }

  return (
    <>
      <FormLegend>Optional Details</FormLegend>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <label>Purchase Order:</label>
          <Select
            value={initialFormValues?.purchase_order_id}
            onChange={(value) => {
              handleChange()
              setInitialFormValue('purchase_order_id', value);
            }}
            style={{ width: '100%' }}
            placeholder="Select a Purchase Order"
            disabled={isDisabled}
          >
            {purchaseOrders.map((purchaseOrder) => {
              let poName = `#${purchaseOrder.purchase_order_number}`;
              if (purchaseOrder.reference_number) {
                poName += ` - ${purchaseOrder.reference_number}`;
              }
              return (
                <Select.Option key={purchaseOrder.id} value={purchaseOrder.id}>
                  {poName}
                </Select.Option>
              );
            })}
          </Select>
          <Button
            style={{ padding: 0 }}
            type="link"
            icon="plus"
            onClick={() => {
              handleChange()
              onClickAddPurchaseOrder()
            }}
          >
            Add Purchase Order
          </Button>
        </Col>
        <Col span={8}>
          <label>Issue report date:</label>
          <DatePicker
            value={initialFormValues?.woIssueReportDate}
            onChange={(date) => {
              handleChange()
              setInitialFormValue('woIssueReportDate', date);
            }}
            format={'MM/DD/YYYY HH:mm:ss'}
            style={{ width: '100%' }}
            showTime
            placeholder="Select date and time"
            disabled={isDisabled}
          />
        </Col>
        <Col span={8}>
          <label>Work Order Start:</label>
          <DatePicker
            format={'MM/DD/YYYY HH:mm:ss'}
            value={initialFormValues?.woStartDate}
            onChange={(date) => {
              handleChange()
              setInitialFormValue('woStartDate', date);
            }}
            style={{ width: '100%' }}
            showTime
            placeholder="Select date and time"
            disabled={isDisabled}
          />
        </Col>
        <Col span={8}>
          <label>Work Order Completed:</label>
          <DatePicker
            format={'MM/DD/YYYY HH:mm:ss'}
            value={initialFormValues?.woCompletedDate}
            onChange={(date) => {
              handleChange()
              setInitialFormValue('woCompletedDate', date);
            }}
            style={{ width: '100%' }}
            showTime
            placeholder="Select date and time"
            disabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <label>Mileage:</label>
          <InputNumber
            value={initialFormValues?.mileage}
            onChange={(value) => {
              handleChange()
              setInitialFormValue('mileage', value);
            }}
            style={{ width: '100%' }}
            placeholder="Mileage"
            disabled={isDisabled}
            inputMode="tel"
          />
        </Col>
        <Col span={12}>
          <label>Engine Hours:</label>
          <InputNumber
            value={initialFormValues?.engineHours}
            onChange={(value) => {
              handleChange()
              setInitialFormValue('engineHours', value);
            }}
            style={{ width: '100%' }}
            placeholder="Engine Hours"
            disabled={isDisabled}
            inputMode="tel"
          />
        </Col>
        {!customFleets.ufpFleet.includes(ShopStore.currentShop?.id) && (
          <>
            <Col span={24}>
              <label>Vendor:</label>
              <Select
                style={{ width: '100%' }}
                value={initialFormValues?.vendor_id}
                onChange={(value) => {
                  handleChange()
                  setInitialFormValue('vendor_id', value);
                }}
                placeholder="Select a User"
                showSearch
                optionFilterProp="children"
                filterOption={filterOption}
                isDisabled={isDisabled}
              >
                {
                  shopVendors.map((vendor) => (
                    <Select.Option key={vendor.id} value={vendor.id}>
                      {vendor.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </Col>
            <Col span={24}>
              <label>Additional Notes:</label>
              <Input.TextArea
                value={initialFormValues?.additionalNotes}
                onChange={(e) => {
                  handleChange()
                  setInitialFormValue('additionalNotes', e.target.value);
                }}
                style={{ width: '100%' }}
                placeholder="Additional Notes"
                disabled={isDisabled}
                rows={6}
              />
            </Col>
          </>
        )}
      </Row>

      {customFleets.ufpFleet.includes(ShopStore.currentShop?.id) && (
        <Row className="limit-size-tablets" style={{ marginTop: '20px' }} gutter={[16, 16]}>
          <Col span={24}>
            <FormLegend>Driver Report Details</FormLegend>
          </Col>
          <Col span={8}>
            <label>Driver Name:</label>
            <Input
              value={initialFormValues?.driverReportDetails?.driverName}
              onChange={(e) => {
                handleChange()
                setInitialFormValue(
                  'driverReportDetails',
                  Object.assign({}, initialFormValues.driverReportDetails, {
                    driverName: e.target.value,
                  })
                );
              }}
              style={{ width: '100%' }}
              disabled={isDisabled}
            />
          </Col>
          <Col span={8}>
            <label>Driver Number:</label>
            <Input
              value={initialFormValues?.driverReportDetails?.driverNumber}
              onChange={(e) => {
                handleChange()
                setInitialFormValue(
                  'driverReportDetails',
                  Object.assign({}, initialFormValues.driverReportDetails, {
                    driverNumber: e.target.value,
                  })
                );
              }}
              style={{ width: '100%' }}
              disabled={isDisabled}
            />
          </Col>
          <Col span={8}>
            <label>Location:</label>
            <Input
              value={initialFormValues?.driverReportDetails?.location}
              onChange={(e) => {
                handleChange()
                setInitialFormValue(
                  'driverReportDetails',
                  Object.assign({}, initialFormValues.driverReportDetails, {
                    location: e.target.value,
                  })
                );
              }}
              style={{ width: '100%' }}
              disabled={isDisabled}
            />
          </Col>
          <Col span={12}>
            <label>Time of Event:</label>
            <DatePicker
              value={initialFormValues?.driverReportDetails?.timeOfEvent}
              onChange={(date) => {
                handleChange()
                setInitialFormValue(
                  'driverReportDetails',
                  Object.assign({}, initialFormValues.driverReportDetails, {
                    timeOfEvent: date,
                  })
                );
              }}
              format={'MM/DD/YYYY HH:mm:ss'}
              style={{ width: '100%' }}
              showTime
              placeholder="Select date and time"
              disabled={isDisabled}
            />
          </Col>
          <Col span={12}>
            <label>Weight of Vehicle:</label>
            <Input
              value={initialFormValues?.driverReportDetails?.weightOfVehicle}
              onChange={(e) => {
                handleChange()
                setInitialFormValue(
                  'driverReportDetails',
                  Object.assign({}, initialFormValues.driverReportDetails, {
                    weightOfVehicle: e.target.value,
                  })
                );
              }}
              style={{ width: '100%' }}
              disabled={isDisabled}
            />
          </Col>
          <Col span={12}>
            <label>Driver Notes:</label>
            <Input
              value={initialFormValues?.driverReportDetails?.driverNotes}
              onChange={(e) => {
                handleChange()
                setInitialFormValue(
                  'driverReportDetails',
                  Object.assign({}, initialFormValues.driverReportDetails, {
                    driverNotes: e.target.value,
                  })
                );
              }}
              style={{ width: '100%' }}
              disabled={isDisabled}
            />
          </Col>
          <Col span={12}>
            <label>Billing Through:</label>
            <Select
              value={initialFormValues?.driverReportDetails?.billingThrough}
              onChange={(value) => {
                handleChange()
                setInitialFormValue(
                  'driverReportDetails',
                  Object.assign({}, initialFormValues.driverReportDetails, {
                    billingThrough: value,
                  })
                );
              }}
              style={{ width: '100%' }}
              disabled={isDisabled}
            >
              <Select.Option
                key={'National account, reference the DR# 0372704'}
              >
                National account, reference the DR# 0372704
              </Select.Option>
              <Select.Option
                key={'National Account, Bridgestone (account number 956315)'}
              >
                National Account, Bridgestone (account number 956315)
              </Select.Option>
              <Select.Option
                key={'National Accounts - Bridgestone (Account #957128)'}
              >
                National Accounts - Bridgestone (Account #957128)
              </Select.Option>
            </Select>
          </Col>
        </Row>
      )}

      <Row className="limit-size-tablets" style={{ marginTop: '20px' }}>
        <Col xs={24} sm={24} lg={24}>
          <FormLegend>Upload Content</FormLegend>
          <ImageUploader
            childRef={(ref) => {
              setImageUploaderContainerRef(ref);
            }}
            onImageUploaded={(file, additionalData) => {
              handleChange()
              handleImageUpload(file, additionalData)
            }}
            wo_files={
              currentWorkOrder?.wo_files || {
                wo_images: [],
                wo_documents: [],
              }
            }
          />
          <div style={documentUploaderStyle}>
            <DocumentUploader
              childRef={(ref) => {
                setDocumentUploaderContainerRef(ref);
              }}
              onFileUploaded={(file, additionalData) => {
                handleChange()
                handleFileUpload(file, additionalData)
              }}
              overrideUploadProps={{
                onPreview: handleDocumentPreview,
              }}
              customClass="document-uploader-with-preview"
              wo_files={
                currentWorkOrder?.wo_files || {
                  wo_images: [],
                  wo_documents: [],
                }
              }
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default WorkOrderOptionalInformation;
