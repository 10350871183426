import { observable, action, decorate, transaction } from 'mobx';
import _, { get } from 'lodash';

import { AbstractStore, TableStore, AsyncData } from 'stores/abstract';
import { AppStore, CurrentUserStore } from 'stores';
import { ConnectivityStatusCache } from 'stores/CacheStore';
import { ShopObject, TripObject } from 'stores/Classes';

import { webServiceProvider } from 'shared';
import { errors } from 'helpers';

class ShopStoreC extends AbstractStore {
  adminId = -1;

  currentShop = {
    id: this.adminId,
    name: 'Admin',
    user: { id: -1 },
    logoUrl: null,
    isAdmin: () => {
      return this.id === this.adminId;
    },
  };

  latestTrips = new AsyncData();

  shopNotifications = new Map();
  connectivityStatus = new TableStore();
  connectivityStatusController = new AbortController();
  routineListController = new AbortController();

  constructor() {
    super();
    this.data.set(
      this.adminId,
      new ShopObject({
        id: this.adminId,
        name: 'Admin',
        user: { id: -1 },
        logoUrl: null,
      })
    );
  }

  MESSAGE = 'Loading In Shop Data';

  isAdmin = () => {
    return this.currentShop.id === this.adminId;
  };

  getShops = async (data) => {
    this.data.clear();
    this.loaded = true;
    this.pending = false;

    AppStore.addLoading(this.MESSAGE);

    const { role, shop } = CurrentUserStore.user;

    if (['dealership', 'technician'].includes(role) && shop && shop.length) {
      try {
        transaction(() => {
          // Atualize `currentShop` com o primeiro `shop` do usuário
          this.currentShop = {
            ...shop[0],
            isAdmin: () => false,
          };

          // Adicione os `shops` ao store
          shop.forEach((s) => this.addShopToStore(s.id, s));
          this.loaded = true;
        });

        console.log(`USER ROLE`, { r: role, s: shop });
      } catch (e) {
        console.log('Erro ao recuperar shops', e);
        this.loaded = false;
        AppStore.addError('Failed to retrieve shops!');
      } finally {
        AppStore.removeLoading(this.MESSAGE);
        this.pending = false;
      }
      return;
    }

    if (role === 'admin') {
      data = { shopType: 'all' };

      try {
        // Defina `currentShop` como o shop admin
        this.addShopToStore(
          this.adminId,
          new ShopObject({
            id: this.adminId,
            name: 'Admin',
            user: { id: -1 },
            logoUrl: null,
          })
        );

        AppStore.removeLoading(this.MESSAGE);

        let returnedShops = await webServiceProvider.getMany('shop', data);

        transaction(() => {
          returnedShops.forEach((s) => this.addShopToStore(s.id, s));
          this.loaded = true;
        });
        this.setCurrentShop(this.adminId);
      } catch (e) {
        this.loaded = false;
        AppStore.addError('Failed to retrieve shops.');
      } finally {
        this.pending = false;
      }
    }
  };

  getShopNames = async (shopIds) => {
    if (_.isEmpty(shopIds)) {
      return {};
    }
    let shopNames = {};
    try {
      const shops = await webServiceProvider.getMany(
        'v1/shops/shop-basic-data',
        {
          shopIds: shopIds.join(','),
        }
      );
      shops.forEach((shop) => {
        shopNames[shop.id] = shop.name;
      });
    } catch (e) {
      AppStore.addError('Failed to retrieve shop names.');
    }
    return shopNames;
  };

  getShop = async (shopId) => {
    try {
      const shop = new ShopObject(
        await webServiceProvider.getById('shop', shopId)
      );
      this.addShopToStore(shopId, shop);
    } catch (e) {
      AppStore.addError(`Failed to retrieve shop with id ${shopId}.`);
    }
  };

  addShopToStore = (shopId, shop) => {
    this.data.set(
      Number(shopId),
      shop instanceof ShopObject ? shop : new ShopObject(shop)
    );
  };

  addShop = async (data, callback = () => {}) => {
    try {
      const shop = await webServiceProvider.post('shop', data);
      const shopObject = new ShopObject(shop);
      this.currentShop.id = shopObject.id;
      this.currentShop.name = shopObject.name;
      this.currentShop.logoUrl = shopObject.logoUrl;
      callback(true, shopObject);
      return shopObject;
    } catch (e) {
      callback(false, e.message);
      throw e;
    }
  };

  setCurrentShop = (shopId) => {
    let shop = this.data.get(shopId);
    if (!shop) {
      this.currentShop.name = 'Admin';
      this.currentShop.id = this.adminId;
      this.currentShop.user = { id: -1 };
      this.currentShop.logoUrl = null;

      return;
    }
    this.currentShop.name = shop.name;
    this.currentShop.id = shop.id;
    this.currentShop.logoUrl = shop.logoUrl;
  };

  addShopToCar = async (shopId, carId) => {
    const result = await webServiceProvider.post('v1/car/shop', {
      shopId,
      carId,
    });
    return result;
  };

  getShopLatestTrips = async (
    shopId = this.currentShop.id,
    limit = 10,
    offset = 0
  ) => {
    let { data } = await webServiceProvider.getMany(
      `v1/shops/${shopId}/trips/latest`,
      {
        limit,
        offset,
      }
    );

    this.latestTrips.data.replace(_.map(data, (item) => new TripObject(item)));
  };

  getShopTripsInRange = async (
    shopId = this.currentShop.id,
    from = null,
    to = null
  ) => {
    const queryParams = {};
    if (from) {
      queryParams.from = from;
    }
    if (to) {
      queryParams.to = to;
    }
    let { data } = await webServiceProvider.getMany(
      `v1/shops/${shopId}/trips/date-grouped`,
      {
        ...queryParams,
      }
    );

    return data;
  };

  reset = () => {
    this.pending = false;
    this.loaded = false;
    this.setCurrentShop(this.adminId);
  };

  getShopNotifications = async (
    shopId = ShopStore.currentShop.id,
    force = false
  ) => {
    try {
      AppStore.addLoading(this.MESSAGE);
      if (this.shopNotifications.get(shopId) && !force) {
        return;
      }
      const notificationConfig = new AbstractStore();

      const emailConfiguration = await webServiceProvider.get(
        `v1/notification/report/shop?shopId=${shopId}`
      );
      const workOrderConfiguration = await webServiceProvider.get(
        `v1/notification/work-order/shop?shopId=${shopId}`
      );
      notificationConfig.setData({
        emailConfiguration,
        workOrderConfiguration: workOrderConfiguration.data,
      });
      this.shopNotifications.set(shopId, notificationConfig);
    } catch (err) {
      errors.response(err);
    } finally {
      AppStore.removeLoading(this.MESSAGE);
    }
  };

  sendEmailVehicleHealthReportConfig = async (
    shopId,
    userIds = [],
    startDate,
    timezone
  ) => {
    try {
      for (const userId of userIds) {
        await webServiceProvider.post('v1/notification/report/shop', {
          id_user: userId,
          id_shop: shopId,
          is_enable: true,
          send_next_email_at: startDate,
          timezone,
          email_report_type: 'DiagnosticEmail',
        });
      }
    } catch (err) {
      throw Error(err.message);
    }
  };

  updateEmailVehicleHealthReportConfig = async (
    id,
    shopId,
    userIds = [],
    startDate,
    timezone
  ) => {
    for (const userId of userIds) {
      try {
        await webServiceProvider.patch('v1/notification/report/shop', {
          id,
          id_user: userId,
          id_shop: shopId,
          is_enable: true,
          send_next_email_at: startDate,
          timezone,
          email_report_type: 'DiagnosticEmail',
        });
      } catch (err) {
        throw Error(err.message);
      }
    }
  };

  sendEmailPriorityAlertsConfig = async (
    shopId,
    users,
    startDate,
    timezone,
    priorityAlerts
  ) => {
    try {
      for (const user of users) {
        await webServiceProvider.post(
          `v1/notification/report/shop?shopId=${shopId}`,
          {
            id_user: user.id,
            id_shop: shopId,
            is_enable: true,
            send_next_email_at: startDate,
            timezone,
            email_report_type: 'PriorityAlerts',
            configuration: {
              subscribeTo: priorityAlerts,
            },
          }
        );
      }
    } catch (err) {
      throw Error(err.message);
    }
  };

  sendPmAlertsConfig = async (shopId, users, startDate, timezone, pmAlerts) => {
    try {
      for (const user of users) {
        const payLoad = {
          id_user: user.id,
          id_shop: shopId,
          is_enable: true,
          send_next_email_at: startDate,
          timezone,
          email_report_type: 'PmAlerts',
          configuration: pmAlerts,
        };
        await webServiceProvider.post(
          `v1/notification/report/shop?shopId=${shopId}`,
          payLoad
        );
      }
    } catch (err) {
      throw Error(err.message);
    }
  };

  updatePmAlertsConfig = async (id, shopId, user, pmAlerts) => {
    try {
      const payLoad = {
        id,
        is_enable: true,
        configuration: pmAlerts,
      };

      await webServiceProvider.patch(
        `v1/notification/report/shop?shopId=${shopId}`,
        payLoad
      );
    } catch (err) {
      throw Error(err.message);
    }
  };

  updateEmailPriorityAlertsConfig = async (
    id,
    shopId,
    emails,
    priorityAlerts
  ) => {
    try {
      await webServiceProvider.patch(
        `v1/notification/report/shop?shopId=${shopId}`,
        {
          id,
          emails: [...emails],
          is_enable: true,
          configuration: {
            subscribeTo: priorityAlerts,
          },
        }
      );
    } catch (err) {
      throw Error(err.message);
    }
  };

  checkAndRemoveEmailBasedNotificationsForUser = async (reports, user) => {
    const oldNotificationsWithEmails = _.chain(reports)
      .filter((a) => !_.isNil(a.emails))
      .value();
    const previousUserNotification = _.find(
      oldNotificationsWithEmails,
      (emailConfig) => {
        let emailsAndNames = [];
        if (_.isString(emailConfig.emails)) {
          emailsAndNames = JSON.parse(emailConfig.emails);
        } else {
          emailsAndNames = emailConfig.emails;
        }
        return emailsAndNames.find((e) => e.email === user.email);
      }
    );
    if (previousUserNotification) {
      // delete the old email report
      await this.deleteEmailPriorityAlertsConfig(previousUserNotification.id);
    }
  };

  deleteEmailPriorityAlertsConfig = async (configurationId) => {
    try {
      await webServiceProvider.delete(
        `v1/emailScheduler?configurationId=${configurationId}`
      );
    } catch (err) {
      throw Error(err.message);
    }
  };

  generatePriorityNumbersFromPriorityList = (priorityStringList = []) => {
    let _priorityCheckedList = [];

    _.each(priorityStringList, (item) => {
      if (item === 'minor') {
        _priorityCheckedList = [..._priorityCheckedList, 0, 1];
      }

      if (item === 'major') {
        _priorityCheckedList = [..._priorityCheckedList, 2, 3];
      }

      if (item === 'critical') {
        _priorityCheckedList = [..._priorityCheckedList, 4, 5];
      }
    });

    return _priorityCheckedList;
  };

  generateDateObject(currentDate, selectedWeekDay, selectedHour) {
    const currentWeekDay = currentDate.day();
    if (currentWeekDay === selectedWeekDay) {
      currentDate.date(currentDate.date() + 7);
    } else {
      while (currentDate.day() !== selectedWeekDay) {
        currentDate.date(currentDate.date() + 1);
      }
    }
    currentDate.second(selectedHour.second());
    currentDate.minute(selectedHour.minute());
    currentDate.hour(selectedHour.hour());
    return currentDate;
  }

  sendFleetSegmentConfiguration = async (
    shopId,
    carIds,
    userIds,
    newFleetName,
    newFleetEmail,
    removeCarsFromOriginalShop
  ) => {
    try {
      await webServiceProvider.post(`v1/shop/${shopId}/segmentFleet`, {
        carIds,
        userIds,
        newFleetName,
        newFleetEmail,
        removeCarsFromOriginalShop,
      });
    } catch (err) {
      throw Error(err.message);
    }
  };

  sendWorkOrderNotificationConfig = async (shopId, patchedConfiguration) => {
    try {
      await webServiceProvider.patch(
        `v1/notification/work-order/shop?shopId=${shopId}`,
        { patchedConfiguration }
      );
    } catch (err) {
      throw Error(err.message);
    }
  };

  getShopSettings = async (shopId = ShopStore.currentShop.id) => {
    try {
      console.log('fetch settings');
      return await webServiceProvider.get(`shop/${shopId}/settings`);
    } catch (err) {
      AppStore.addError('Error in loading shop settings!');
    }
  };

  getShopTaxSettings = async (shopId = ShopStore.currentShop.id) => {
    try {
      const { result } = await ShopStore.getShopSettings(shopId);
      if (
        !_.isNil(result) &&
        !_.isNil(result[0]) &&
        !_.isNil(result[0].settings)
      ) {
        const currentSettings = result[0].settings.fleetsettings;

        return currentSettings;
      }
    } catch (err) {
      console.error('ERROR TO LOAD TAX SETTINGS');
    }
  };

  getColumns = async (tableName) => {
    try {
      const settings = await ShopStore.getShopSettings(this.shopId);

      const tables = _.get(settings.result, '[0].settings.table');
      if (tables) {
        const currentTable = _.find(tables, { name: tableName });

        return currentTable;
      }
    } catch (e) {
      console.log('wrong table');
    } finally {
      console.log('finish loaded table');
    }
  };

  upsertShopSettings = async (
    shopId = ShopStore.currentShop.id,
    settings = {}
  ) => {
    try {
      await webServiceProvider.put(`shop/${shopId}/settings`, settings);
    } catch (err) {
      AppStore.addError('Error in adding shop settings!');
    }
  };

  getShopLocations = async (shopId = ShopStore.currentShop.id) => {
    try {
      // locations are saved in shop settings for now
      const shopSettings = await ShopStore.getShopSettings(shopId);
      return get(shopSettings, 'result[0].settings.locations', []);
    } catch (err) {
      AppStore.addError('Error in loading shop locations!');
    }
  };

  getConnectivityStatus = async (
    shopId = ShopStore.currentShop.id,
    tableStore = new TableStore()
  ) => {
    try {
      this.connectivityStatusController.abort();

      this.connectivityStatus = ConnectivityStatusCache.getData(shopId);

      if (_.isNil(this.connectivityStatus)) {
        this.connectivityStatus = tableStore;

        this.connectivityStatus.pending = true;
        this.connectivityStatus.loaded = false;

        this.connectivityStatusController = new AbortController();
        this.connectivityStatusSignal = this.connectivityStatusController.signal;

        let data = await webServiceProvider.get(
          `v1/shop/${shopId}/activeVehicles`,
          this.connectivityStatusSignal
        );

        this.connectivityStatus.data.push(data);
        this.connectivityStatus.setDataMetadata(
          this.connectivityStatus.data.length
        );

        ConnectivityStatusCache.setData(shopId, this.connectivityStatus);
      }
    } catch (err) {
      errors.response(err);
    }
  };

  getRoutinesList = async (
    tableStore = new TableStore(),
    shopId = ShopStore.currentShop.id
  ) => {
    try {
      tableStore.data = [];

      tableStore.loaded = false;
      tableStore.pending = true;

      this.routineListController.abort();

      this.routineListController = new AbortController();
      this.routineListSignal = this.routineListController.signal;

      let data = await webServiceProvider.get(
        `v1/routines/shop/${shopId}`,
        this.routineListSignal
      );

      tableStore.data = data;
      tableStore.setDataMetadata(data.length);
    } catch (err) {
      errors.response(err);
    }
  };

  updateRoutineById = async (routineId, payload) => {
    await webServiceProvider.put(`v1/routines/${routineId}`, {
      data: { ...payload },
    });
  };

  getShopSubfleetEcosystem = async (shopId) => {
    try {
      return await webServiceProvider.get(
        `v1/shops/${shopId}/subfleetEcosystem`
      );
    } catch (err) {
      errors.response(err);
    }
  };

  getShopArchiveShopId = async (shopId) => {
    try {
      const shop = await webServiceProvider.getById('shop', shopId);
      return shop.id_archive_shop;
    } catch (err) {
      errors.response(err);
    }
  };

  getShopDataForVehicleRepeatVisits = async (shopId, days, lim) => {
    // of interest
    try {
      if (!shopId || shopId === -1) {
        return [];
      }
      return await webServiceProvider.get(
        `v1/visits-frequency/${shopId}?days_back=${days}&limit=${lim}&type=freq`
      );
    } catch (err) {
      throw err;
    }
  };

  getUnitIDsFromCarIds = async (carIds) => {
    // of interest
    try {
      if (!carIds || carIds.length === 0) {
        return [];
      }

      // Convert array to comma-separated string
      const carIdsString = carIds.join(',');

      return await webServiceProvider.get(
        `v1/unit-ids?car_ids=${carIdsString}`
      );
    } catch (err) {
      throw err;
    }
  };

  getShopDataForVehicleCosts = async (shopId, days, lim, filter = {}) => {
    // of interest
    try {
      if (!shopId || shopId === -1) {
        return [];
      }
      // construct query params
      const queryParams = {
        days_back: days,
        limit: lim,
        type: 'cost',
        ...filter,
      };

      // prevent undefined params
      Object.keys(queryParams).forEach((key) => {
        if (queryParams[key] === undefined) {
          delete queryParams[key];
        }
      });

      // check if carIds is an array
      if (queryParams.carIds && !Array.isArray(queryParams.carIds)) {
        queryParams.carIds = [queryParams.carIds];
      }

      // join carIds into a comma-separated string
      if (queryParams.carIds) {
        queryParams.carIds = queryParams.carIds.join(',');
      }

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      return await webServiceProvider.get(
        `v1/visits-frequency/${shopId}?${queryString}`
      );
    } catch (err) {
      throw err;
    }
  };

  getShopDataForVehicleAverageCostPerVisit = async (shopId, days, lim) => {
    // of interest
    try {
      if (!shopId || shopId === -1) {
        return [];
      }
      return await webServiceProvider.get(
        `v1/visits-frequency/${shopId}?days_back=${days}&limit=${lim}&type=avg`
      );
    } catch (err) {
      throw err;
    }
  };

  getShopDataForReasonsForRepeatVisits = async (shopId) => {
    // of interest
    try {
      if (!shopId || shopId === -1) {
        return [];
      }
      return await webServiceProvider.get(
        `v1/shops/${shopId}/shop-data-for-reasons-for-freq` // subject to change once reasons data is received
      );
    } catch (err) {
      throw err;
    }
  };

  getShopDataForReasonsForCost = async (shopId) => {
    // of interest
    try {
      if (!shopId || shopId === -1) {
        return [];
      }
      return await webServiceProvider.get(
        `v1/shops/${shopId}/shop-data-for-reasons-for-cost` // subject to change once reasons data is received
      );
    } catch (err) {
      throw err;
    }
  };

  getShopDataForVehiclePMQuality = async (shopId, lim) => {
    // of interest
    try {
      if (!shopId || shopId === -1) {
        return [];
      }
      return await webServiceProvider.get(
        `v1/visits-frequency/${shopId}?limit=${lim}&type=pm_diff`
      );
    } catch (err) {
      throw err;
    }
  };

  // * DUPLICATES FOR VMRS PARTS REPORT PAGE WIDGETS
  // MODIFY ENDPOINT REQUEST FOR PARTS INSTEAD
  // DATA DOESN'T EXIST YET
  getShopDataForPartAverageCostPerVisit = async (shopId, days, lim) => {
    // of interest
    try {
      if (!shopId || shopId === -1) {
        return [];
      }
      return await webServiceProvider.get(
        `v1/visits-frequency/${shopId}?days_back=${days}&limit=${lim}&type=avg`
      );
    } catch (err) {
      throw err;
    }
  };

  // MODIFY ENDPOINT REQUEST FOR PARTS INSTEAD
  // DATA DOESN'T EXIST YET
  getShopDataForPartCosts = async (shopId, days, lim) => {
    // of interest
    try {
      if (!shopId || shopId === -1) {
        return [];
      }
      return await webServiceProvider.get(
        `v1/visits-frequency/${shopId}?days_back=${days}&limit=${lim}&type=cost`
      );
    } catch (err) {
      throw err;
    }
  };

  // MODIFY ENDPOINT REQUEST FOR PARTS INSTEAD
  getShopDataForPartRepeatVisits = async (shopId, days, lim) => {
    // of interest
    try {
      if (!shopId || shopId === -1) {
        return [];
      }
      return await webServiceProvider.get(
        `v1/parts-frequency/${shopId}?days_back=${days}&limit=${lim}&type=freq`
      );
    } catch (err) {
      throw err;
    }
  };

  getAssetDurationData = async (
    shopId,
    days = 90,
    limit = 20,
    status = 'running',
    search = '',
    additionalFilters = {}
  ) => {
    try {
      if (!shopId || shopId === -1) {
        throw new Error('Invalid Shop ID');
      }

      // construct query params
      const queryParams = {
        days: days,
        limit: limit,
        statuses: status,
        search: search,
        ...additionalFilters,
      };

      // prevent undefined, null, empty array, or empty string params
      Object.keys(queryParams).forEach((key) => {
        if (
          queryParams[key] === undefined ||
          queryParams[key] === null ||
          queryParams[key] === ''
        ) {
          delete queryParams[key];
        }
      });

      // check if carIds is an array
      if (queryParams.carIds && !Array.isArray(queryParams.carIds)) {
        queryParams.carIds = [queryParams.carIds];
      }
      // check if not an array, convert to array
      if (queryParams.vendor && !Array.isArray(queryParams.vendor)) {
        queryParams.vendor = [queryParams.vendor];
      }
      if (queryParams.model && !Array.isArray(queryParams.model)) {
        queryParams.model = [queryParams.model];
      }
      if (queryParams.year && !Array.isArray(queryParams.year)) {
        queryParams.year = [queryParams.year];
      }
      if (queryParams.make && !Array.isArray(queryParams.make)) {
        queryParams.make = [queryParams.make];
      }

      // join into a comma-separated string
      if (queryParams.carIds) {
        queryParams.carIds = queryParams.carIds.join(',');
      }
      if (queryParams.vendor) {
        queryParams.vendor = queryParams.vendor.join(',');
      }
      if (queryParams.make) {
        queryParams.make = queryParams.make.join(',');
      }
      if (queryParams.model) {
        queryParams.model = queryParams.model.join(',');
      }
      if (queryParams.year) {
        queryParams.year = queryParams.year.join(',');
      }

      const queryString = Object.entries(queryParams)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      const response = await webServiceProvider.get(
        `v1/cars/asset-duration/${shopId}?${queryString}`
      );
      return response;
    } catch (err) {
      AppStore.addError('Failed to retrieve asset duration data.');
      throw err;
    }
  };

  getAssetAvailabilityData = async (shopId, startDate, endDate) => {
    try {
      if (!shopId || shopId === -1) {
        throw new Error('Invalid Shop ID');
      }

      const response = await webServiceProvider.get(
        `v1/cars/asset-availability/${shopId}?startDate=${startDate}&endDate=${endDate}`
      );
      return response;
    } catch (err) {
      AppStore.addError('Failed to retrieve asset availability data.');
      throw err;
    }
  };

  getShopMakes = async (shopId) => {
    try {
      if (_.isNil(shopId) || shopId === -1) {
        return [];
      }
      const { makes } = await webServiceProvider.get(`v1/cars/makes/${shopId}`);
      return makes;
    } catch (err) {
      throw err;
    }
  };

  getShopModels = async (shopId) => {
    try {
      if (_.isNil(shopId) || shopId === -1) {
        return [];
      }
      const { models } = await webServiceProvider.get(`v1/cars/models/${shopId}`);
      return models;
    } catch (err) {
      throw err;
    }
  };

  getShopYears = async (shopId) => {
    try {
      if (_.isNil(shopId) || shopId === -1) {
        return [];
      }
      const { years } = await webServiceProvider.get(`v1/cars/years/${shopId}`);
      return years;
    } catch (err) {
      throw err;
    }
  };
}

decorate(ShopStoreC, {
  currentShop: observable,
  latestTrips: observable,
  connectivityStatus: observable,
  shopNotifications: observable,
  setCurrentShop: action,
  getShops: action,
  addShopToStore: action,
  getEmailVehicleHealthReportConfig: action,
  getConnectivityStatus: action,
  sendEmailPriorityAlertsConfig: action,
  sendEmailVehicleHealthReportConfig: action,
  updateEmailVehicleHealthReportConfig: action,
  getRoutinesList: action,
});

var ShopStore = new ShopStoreC();
export default ShopStore;
